import { poolsGql } from "@/api/pool/pools";
import { useQuery } from "@vue/apollo-composable";
import { computed, Ref, watch } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import {
  PoolsVariables,
  Pools,
  Pools_pools_PoolItemResults,
} from "@/api/pool/__generated__/Pools";
import isEmpty from "lodash/isEmpty";
import { PoolsInput, PoolStatus } from "../../../__generated__/globalTypes";
import { parsePoolItem } from "@/shared/utils/pool";
import { omitBy } from "lodash";

const defaultSearchFilter: PoolsInput = {
  status: PoolStatus.ACTIVE,
  pagination: {
    offset: 0,
    pageSize: 20,
  },
  startDate: "",
  endDate: "",
  query: "",
};

export const usePools = (searchFilter: Ref<PoolsInput>) => {
  watch(searchFilter, (value) => console.log("usePools:searchFilter", value));

  const { result: poolsResult, loading: poolsListLoading } = useQuery<
    Pools,
    PoolsVariables
  >(
    poolsGql,
    () => ({
      input: omitBy(
        {
          ...defaultSearchFilter,
          ...searchFilter.value,
        },
        (item) => !item // remove empty items, pagination won't be checked because it's nested
      ),
    }),
    { fetchPolicy: "cache-and-network" }
  );

  const poolsList = computed(() => {
    const parsedResponse = parseGqlResponse<Pools_pools_PoolItemResults>(
      "PoolItemResults",
      poolsResult.value
    );

    console.log("poolsList: parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !parsedResponse) {
      /**
       * Get error message
       */
      console.error("poolsList error", { parsedResponse });
    }

    // return pool list results
    return parsedResponse.data?.results?.map((item) => parsePoolItem(item));
  });

  return { searchFilter, poolsList, poolsListLoading };
};
