import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "caret-counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_paragraph, { class: "font-bold m-0" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.score), 1)
      ]),
      _: 1
    }),
    (_ctx.addedScore)
      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
          key: 0,
          class: "small m-0",
          type: _ctx.addedScore < 0 ? 'danger' : 'success'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString((_ctx.addedScore > 0 ? "+" : "") + _ctx.addedScore), 1)
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true)
  ]))
}