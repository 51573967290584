import gql from "graphql-tag";

export const poolsGql = gql`
  query Pools($input: PoolsInput!) {
    pools(input: $input) {
      ... on PoolItemResults {
        results {
          id
          voteId
          hash
          status
          created
          poolDetail {
            name
            ticker
            url
            logo
            longDescription
            shortDescription
          }
          isVoted
          isOwned
          pledgeAmount
          roaPercentage
          roaPercentageLifetime: lifetimePercentage
          luckPercentage
          epoch {
            id
            number
          }
          poolEpochDetail {
            blocksCount
            delegatorsCount
            activeStakeAmount
            poolRewardsAmount
            delegatorRewardsAmount
            poolHash
            epochNo
          }
          poolVoteDetail {
            voteCount
            voteStrike
            voteCountDifference
          }
        }
        pageInfo {
          nextOffset
        }
        totalCount
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
