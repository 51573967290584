
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { adaCurrencyFormatter } from "@/shared/utils/adaFormatters";
import { Pools_pools_PoolItemResults_results_poolEpochDetail } from "@/api/pool/__generated__/Pools";

export default defineComponent({
  props: {
    poolEpochDetail: {
      type: Object as PropType<Pools_pools_PoolItemResults_results_poolEpochDetail>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {
    adaCurrencyFormatter,
  },
});
