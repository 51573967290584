/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum DelegationTransactionType {
  DELEGATION = "DELEGATION",
  UNDELEGATION = "UNDELEGATION",
}

export enum PoolStatus {
  ACTIVE = "ACTIVE",
  DEAD = "DEAD",
  INACTIVE = "INACTIVE",
}

/**
 * An enumeration.
 */
export enum PoolVoteStatus {
  INVALID = "INVALID",
  VALID = "VALID",
}

/**
 * An enumeration.
 */
export enum UserSecretKeyStatus {
  UNUSED = "UNUSED",
  USED = "USED",
}

export interface AuthenticateViaAvataradaInput {
  authorizationCode: string;
  redirectUri?: string | null;
}

export interface CreatePoolNewsInput {
  poolHash: string;
  body: string;
}

export interface CreatePoolVoteInput {
  poolHash: string;
}

export interface DeletePoolNewsInput {
  poolNewsId: number;
}

export interface PaginationInput {
  after?: string | null;
  pageSize: number;
}

export interface PoolBlocksInput {
  poolHash: string;
  pagination?: SimplePaginationInput | null;
}

export interface PoolDelegatorsInput {
  poolHash: string;
  pagination?: SimplePaginationInput | null;
}

export interface PoolDetailsInput {
  poolHash: string;
}

export interface PoolGraphDetailsInput {
  poolHash?: string | null;
  startEpochNumber?: number | null;
  endEpochNumber?: number | null;
}

export interface PoolHistoriesInput {
  poolHash?: string | null;
  pagination?: SimplePaginationInput | null;
}

export interface PoolNewsInput {
  poolHash: string;
}

export interface PoolRelaysInput {
  pagination?: SimplePaginationInput | null;
  poolHash?: string | null;
}

export interface PoolRewardsInput {
  poolHash: string;
  pagination?: SimplePaginationInput | null;
}

export interface PoolsInput {
  pagination?: SimplePaginationInput | null;
  epochNumber?: number | null;
  status?: PoolStatus | null;
  query?: string | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface SimplePaginationInput {
  offset?: number | null;
  pageSize?: number | null;
}

export interface UnvoteInput {
  voteId: number;
}

export interface UpdatePoolInput {
  poolHash: string;
  longDescription: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
