import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pool-list-item-cell-pool-stake flex" }
const _hoisted_2 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_progress, {
      percent: _ctx.poolEpochDetail.stakeSaturation,
      showInfo: false
    }, null, 8, ["percent"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_paragraph, { class: "-mb-1 whitespace-nowrap" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.adaCurrencyFormatter(_ctx.poolEpochDetail.activeStakeAmount)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_typography_text, {
        class: "small m-0 whitespace-nowrap",
        type: _ctx.poolEpochDetail.stakeGain ? 'success' : 'danger'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.poolEpochDetail.stakeGain ? "\u2191" : "\u2193") + " " + _toDisplayString(_ctx.adaCurrencyFormatter(_ctx.poolEpochDetail.stakeLastAdd)), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ])
  ]))
}