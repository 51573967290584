import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaretCounter = _resolveComponent("CaretCounter")!
  const _component_ButtonVote = _resolveComponent("ButtonVote")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_PoolListItemCellPoolName = _resolveComponent("PoolListItemCellPoolName")!
  const _component_PoolListItemCellPoolStake = _resolveComponent("PoolListItemCellPoolStake")!
  const _component_CustomTable = _resolveComponent("CustomTable")!

  return (_openBlock(), _createBlock(_component_CustomTable, {
    pageSize: _ctx.localPageSize,
    "onUpdate:pageSize": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localPageSize) = $event)),
    class: "pool-list",
    "row-class-name": () => _ctx.rowClasses.join(' '),
    "data-source": _ctx.poolsList,
    columns: _ctx.columns,
    "row-key": "id",
    size: "small",
    "show-pagination": _ctx.showPagination,
    loading: _ctx.poolsListLoading,
    onOnRowClick: _ctx.handleRowSelect
  }, {
    position: _withCtx(({ text: poolVoteDetail }) => [
      _createVNode(_component_CaretCounter, {
        score: poolVoteDetail?.voteCount,
        "added-score": poolVoteDetail?.voteCountDifference ?? 0
      }, null, 8, ["score", "added-score"])
    ]),
    vote: _withCtx(({ record }) => [
      _createVNode(_component_ButtonVote, {
        class: "pool-list__btn",
        size: "small",
        "is-voted": record.isVoted,
        "pool-hash": record?.hash,
        "vote-id": record.voteId ?? 0
      }, null, 8, ["is-voted", "pool-hash", "vote-id"])
    ]),
    icon: _withCtx(({ text }) => [
      _createVNode(_component_a_image, {
        src: text,
        width: 40,
        fallback: _ctx.productFallbackIcon
      }, null, 8, ["src", "fallback"])
    ]),
    poolDetail: _withCtx(({ text: poolDetail }) => [
      _createVNode(_component_PoolListItemCellPoolName, { "pool-detail": poolDetail }, null, 8, ["pool-detail"])
    ]),
    date: _withCtx(({ text }) => [
      text
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formatDatePretty(text)), 1))
        : _createCommentVNode("", true)
    ]),
    roa: _withCtx(({ record }) => [
      _createTextVNode(_toDisplayString(`${record.roaPercentage}% / ${record.roaPercentageLifetime}%`), 1)
    ]),
    stake: _withCtx(({ text: poolEpochDetail }) => [
      _createVNode(_component_PoolListItemCellPoolStake, { "pool-epoch-detail": poolEpochDetail }, null, 8, ["pool-epoch-detail"])
    ]),
    luck: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(text) + "% ", 1)
    ]),
    pledge: _withCtx(({ text }) => [
      _createTextVNode(_toDisplayString(_ctx.localeFormatterPretty(text)), 1)
    ]),
    _: 1
  }, 8, ["pageSize", "row-class-name", "data-source", "columns", "show-pagination", "loading", "onOnRowClick"]))
}