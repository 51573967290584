
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    score: {
      type: Number,
      required: false,
    },
    addedScore: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
