/**
 * Insert default value if shouldInsert is true
 *
 * Note: This will invoke data parameter, if you need optional invocation use ternary if else
 */
export const insertIf = (shouldInsert: boolean, data, fallbackData = {}) => {
  if (shouldInsert) {
    return data;
  }

  return fallbackData;
};
