
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { openInNewTab } from "@/shared/utils/browser";
import PoolHandles from "@/shared/components/Pool/PoolHandles.vue";
import { parsePoolHandlers } from "@/shared/utils/pool";
import { Pools_pools_PoolItemResults_results_poolDetail } from "@/api/pool/__generated__/Pools";

export default defineComponent({
  props: {
    poolDetail: {
      type: Object as PropType<Pools_pools_PoolItemResults_results_poolDetail>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    return {
      t,
      handles: computed(() => parsePoolHandlers(props?.poolDetail)),
    };
  },
  methods: { openInNewTab },
  components: { PoolHandles },
});
