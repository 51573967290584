
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { localeFormatterPretty } from "@/shared/utils/adaFormatters";
import PoolListItemCellPoolName from "@/shared/components/List/PoolList/PoolListItemCellPoolName.vue";
import PoolListItemCellPoolStake from "@/shared/components/List/PoolList/PoolListItemCellPoolStake.vue";
import productFallbackIcon from "@/assets/icons/product_fallback.svg";
import CaretCounter from "@/shared/components/CaretCounter.vue";
import ButtonVote from "@/shared/components//Buttons/ButtonVote.vue";
import CustomTable from "../../CustomTable.vue";
import { formatDatePretty } from "@/shared/utils/date";
import { usePools } from "@/shared/composables/usePools";
import isEmpty from "lodash/isEmpty";
import { formatGraphQLDate } from "@/shared/utils/date";
import { PoolsInput, SimplePaginationInput } from "__generated__/globalTypes";
import { useVModel } from "vue-composable";
import { insertIf } from "@/shared/utils/object";
import { useBreakpoints } from "@/shared/utils/breakpoints";

type CustomQueryFilter = {
  queryText?: string;
  dateRange?: [any, any];
};

export default defineComponent({
  components: {
    PoolListItemCellPoolName,
    PoolListItemCellPoolStake,
    CaretCounter,
    ButtonVote,
    CustomTable,
  },
  props: {
    showPagination: Boolean,
    pageSize: {
      type: Number as PropType<SimplePaginationInput["pageSize"]>,
      default: 12,
    },
    rowClasses: {
      type: Array as PropType<string[]>,
      default: () => ["cursor-pointer"],
    },
    // eslint-disable-next-line vue/require-default-prop
    queryFilters: {
      type: Object as PropType<CustomQueryFilter>,
      required: false,
    },
    showDateColumn: Boolean,
  },
  emits: ["on-row-click"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const localPageSize = useVModel(props, "pageSize");
    const { isMobileDown } = useBreakpoints();

    const localQuery = computed<PoolsInput>(() => {
      const hasDateRange =
        !isEmpty(props.queryFilters?.dateRange) &&
        !!props.queryFilters?.dateRange?.[0] &&
        !!props.queryFilters?.dateRange?.[1];

      return {
        pagination: {
          pageSize: localPageSize.value,
        },
        ...insertIf(
          !!props.queryFilters?.queryText,
          { query: props.queryFilters?.queryText },
          {}
        ),
        ...(hasDateRange
          ? {
              startDate: formatGraphQLDate(props.queryFilters?.dateRange?.[0]),
              endDate: formatGraphQLDate(props.queryFilters?.dateRange?.[1]),
            }
          : {}),
      };
    });

    const { poolsList, poolsListLoading } = usePools(localQuery);

    const columns = computed(() => {
      return [
        {
          title: t("Vote"),
          dataIndex: "poolVoteDetail",
          key: "position",
          slots: { customRender: "position" },
          width: 48, // add width so it will occupy only needed spaces
        },
        {
          dataIndex: "vote",
          key: "vote",
          slots: { title: "customTitle", customRender: "vote" },
          width: 124, // add width so it will occupy only needed spaces
        },
        {
          title: "",
          dataIndex: "poolDetail.logo",
          key: "icon",
          slots: { customRender: "icon" },
          width: 56, // add width so it will occupy only needed spaces, 56 = icon width + padding-y
        },
        {
          title: t("Pool"),
          dataIndex: "poolDetail",
          key: "poolDetail",
          slots: { customRender: "poolDetail" },
        },
        ...(isMobileDown.value
          ? []
          : [
              ...(props.showDateColumn
                ? [
                    {
                      title: t("Date"),
                      dataIndex: "date",
                      key: "date",
                      slots: { customRender: "date" },
                      sorter: true,
                    },
                  ]
                : []),
              {
                title: t("ROA / Lifetime"),
                key: "roa",
                slots: { customRender: "roa" },
              },
              {
                title: t("Stake"),
                dataIndex: "poolEpochDetail",
                key: "stake",
                slots: { customRender: "stake" },
              },
              {
                title: t("Luck"),
                dataIndex: "luckPercentage",
                key: "luck",
                slots: { customRender: "luck" },
                sorter: true,
              },
              {
                title: t("Pledge"),
                dataIndex: "pledgeAmount",
                key: "pledge",
                slots: { customRender: "pledge" },
                sorter: true,
              },
            ]),
      ];
    });

    const handleRowSelect = (rowRecord) => {
      emit("on-row-click", rowRecord);
    };

    return {
      t,
      localPageSize,
      localQuery,
      columns,
      handleRowSelect,
      productFallbackIcon,
      poolsList,
      poolsListLoading,
    };
  },
  methods: { localeFormatterPretty, formatDatePretty },
});
