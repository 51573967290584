import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pool-list-item-cell-pool-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_PoolHandles = _resolveComponent("PoolHandles")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_paragraph, { class: "pool-list-item-cell-pool-name__value -mb-1" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.poolDetail.name), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_PoolHandles, { handles: _ctx.handles }, null, 8, ["handles"])
  ]))
}